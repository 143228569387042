import React from 'react';
import { Link } from 'gatsby';
import { ButtonLink } from '../buttons';
import './navigation.scss';

export const Navigation = ({ button, showPositions }) => (
  <nav className='main-navigation'>
    <ul>
      <li>
        <Link className='navigation-link' to='/tjenester'>
          Tjenester
        </Link>
      </li>
      {/* <li>
        <Link className='navigation-link' to='/om-oss'>
          Om oss
        </Link>
      </li> */}
      {showPositions && (
        <li>
          <Link className='navigation-link' to='/ledige-stillinger'>
            Ledige stillinger
          </Link>
        </li>
      )}
      <li>
        {button ? (
          <ButtonLink
            size='small'
            type='primary'
            to='/kontakt'
            text='Kontakt oss'
          />
        ) : (
          <Link className='navigation-link' to='/kontakt'>
            Kontakt oss
          </Link>
        )}
      </li>
    </ul>
  </nav>
);
