import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import './buttons.scss';

const Button = ({ children, fullWidth, inverted, order, type, size }) => (
  <button
    className={`button ${inverted ? 'inverted' : ''} ${
      fullWidth ? 'button--full-width' : ''
    }`}
    button-type={type}
    button-size={size}
    style={{
      order: order ? order : null,
    }}
  >
    <span className='button-text'>{children}</span>
  </button>
);

export default Button;

export const ButtonLink = ({ to, text, type, fullWidth, size }) => (
  <Link to={to}>
    <Button
      type={type}
      {...(size ? 'button-size="' + size + '"' : null)}
      {...fullWidth}
    >
      {text}
    </Button>
  </Link>
);

export const ButtonGroup = ({ children, orientation, align }) => (
  <div className='button-group' orientation={orientation} alignment={align}>
    {children}
  </div>
);

Button.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
};

Button.defaultProps = {
  children: 'Lorem ipsum',
  type: 'regular',
};

ButtonGroup.propTypes = {
  children: PropTypes.element.isRequired,
};

ButtonGroup.defaultProps = {
  orientation: 'row',
};
