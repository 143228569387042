import React from 'react';
import PropTypes from 'prop-types';
import './wrapper.scss';

const Wrapper = ({ width, center, children, className }) => (
  <div
    className={'wrapper' + (className ? ` ${className}` : '')}
    style={{
      width: 'inherit',
      maxWidth: width ? width : 1440,
      margin: center ? '0 auto' : '0',
    }}
  >
    {children}
  </div>
);

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Wrapper;
