import React from 'react';
import { Navigation } from '../navigation';
import Wrapper from '../wrapper';
import './footer.scss';

const Year = new Date().getFullYear();

const Footer = () => (
  <footer id='site-footer'>
    <Wrapper center>
      <p>
        En del av{' '}
        <a target='_blank' rel='noreferrer' href='https://layer.no'>
          Layer-gruppen
        </a>
      </p>
      <Navigation button showPositions />
    </Wrapper>
    <Wrapper center>
      <div className='information'>
        <p>&copy; {Year}</p>
        {/* <Link to='/personvern'>Personvern</Link> */}
      </div>
    </Wrapper>
  </footer>
);

export default Footer;
