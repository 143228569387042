import React from 'react';
import PropTypes from 'prop-types';
import Header from '../layout/header';
import Footer from '../layout/footer';
import './layout.scss';

const Layout = ({ children, type }) => {
  return (
    <>
      <Header />
      <main id='layout' page-type={type}>
        {children}
      </main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
