import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Navigation } from '../navigation';
import './header.scss';

export function NavIcon() {
  const [isOpen, setOpen] = useState(true);

  const openClose = () => {
    setOpen(!isOpen);
  };

  const Icon = () => {
    if (isOpen !== true) {
      return (
        <svg
          width='24px'
          height='24px'
          viewBox='0 0 24 24'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g
            className='icon'
            stroke='none'
            strokeWidth='1'
            fill='none'
            fillRule='evenodd'
          >
            <g id='Close'>
              <path
                fill='#000000'
                d='M17.2928932,5.29289322 L18.7071068,6.70710678 L13.4138932,11.9998932 L18.7071068,17.2928932 L17.2928932,18.7071068 L11.9998932,13.4138932 L6.70710678,18.7071068 L5.29289322,17.2928932 L10.5858932,11.9998932 L5.29289322,6.70710678 L6.70710678,5.29289322 L11.9998932,10.5858932 L17.2928932,5.29289322 Z'
              ></path>
              <rect id='Rectangle' x='0' y='0' width='24' height='24'></rect>
            </g>
          </g>
        </svg>
      );
    } else {
      return (
        <svg
          width='24px'
          height='24px'
          viewBox='0 0 24 24'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g
            className='icon'
            stroke='none'
            strokeWidth='0'
            fill='none'
            fillRule='evenodd'
          >
            <g id='Menu'>
              <rect fill='#000000' x='4' y='6' width='16' height='2'></rect>
              <rect fill='#000000' x='4' y='11' width='16' height='2'></rect>
              <rect fill='#000000' x='4' y='16' width='16' height='2'></rect>
              <rect x='0' y='0' width='24' height='24'></rect>
            </g>
          </g>
        </svg>
      );
    }
  };

  return (
    <button
      aria-label='Åpne menyen'
      className={'menu-icon' + (isOpen !== true ? ` is-toggled` : ``)}
      onClick={openClose}
    >
      <Icon />
    </button>
  );
}

const Header = () => (
  <header id='main-header'>
    <Link className='logo-link' to='/' aria-label='Hjem'>
      <svg
        width='163px'
        height='24px'
        viewBox='0 0 163 24'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g
          id='wordmark'
          transform='translate(36.430577, 0.000000)'
          fill='#FFFFFF'
        >
          <path d='M11.3086138,23.7393405 C16.5454647,23.7393405 20.1439549,20.6876108 21.3645353,16.4151568 L18.5694938,15.3872108 C17.6379215,18.5031892 15.196112,20.8482162 11.3086138,20.8482162 C7.16421964,20.8482162 3.21249745,17.7964865 3.21249745,11.8536 C3.21249745,5.91074595 7.16421964,2.89112757 11.3086138,2.89112757 C15.035552,2.89112757 17.5415855,4.81855135 18.4089338,8.25577297 L21.3324233,7.22782703 C20.1760669,2.82687892 16.5133527,2.30446293e-15 11.3086138,2.30446293e-15 C5.65430691,2.30446293e-15 3.22663916e-14,4.24031351 3.22663916e-14,11.8536 C3.22663916e-14,19.4669189 5.42952291,23.7393405 11.3086138,23.7393405 Z'></path>
          <path d='M43.9009964,23.2574919 L47.3064902,23.2574919 L38.2466895,0.481855135 L34.6164116,0.481855135 L25.5562865,23.2574919 L28.9296684,23.2574919 L31.3393658,16.8970378 L41.4273993,16.8970378 L43.9009964,23.2574919 Z M36.3511084,3.75846486 L40.3028305,13.973773 L32.4636102,13.973773 L36.3511084,3.75846486 Z'></path>
          <path d='M61.141248,23.7393405 C65.7676465,23.7393405 69.3337004,20.8482162 69.3337004,15.4836 L69.3337004,0.481855135 L66.1854269,0.481855135 L66.1854269,15.3872108 C66.1854269,18.856573 64.3540698,20.8161081 61.141248,20.8161081 C57.9605382,20.8161081 56.0652815,18.856573 56.0652815,15.3872108 L56.0652815,0.481855135 L52.9487956,0.481855135 L52.9487956,15.4836 C52.9487956,20.8482162 56.5148495,23.7393405 61.141248,23.7393405 Z'></path>
          <polygon points='91.9168698 23.2574919 91.9168698 20.3021189 81.15416 20.3021189 81.15416 0.481855135 78.0058865 0.481855135 78.0058865 23.2574919'></polygon>
          <polygon points='102.032474 23.2574919 102.032474 0.481855135 98.8196524 0.481855135 98.8196524 23.2574919'></polygon>
          <path d='M125.558569,5.65375135 C125.044452,3.18024 122.988311,2.30446293e-15 117.847796,2.30446293e-15 C113.639178,2.30446293e-15 110.33002,3.21236432 110.33002,6.77808649 C110.33002,10.1832 112.643057,12.3033405 115.887991,13.0100757 L119.132925,13.7167784 C121.414175,14.198627 122.538419,15.6120649 122.538419,17.2503892 C122.538419,19.2420324 120.996394,20.8803568 117.847796,20.8803568 C114.410191,20.8803568 112.610945,18.5353297 112.386161,15.9975568 L109.366336,16.9612865 C109.783792,20.2057622 112.386161,23.7393405 117.879908,23.7393405 C122.731415,23.7393405 125.751241,20.5270054 125.751241,16.9933946 C125.751241,13.8131676 123.630876,11.436 119.839713,10.6008 L116.43422,9.86195676 C114.506527,9.44435676 113.51073,8.19152432 113.51073,6.55323243 C113.51073,4.43305946 115.342087,2.76263351 117.91202,2.76263351 C121.09273,2.76263351 122.474195,5.0112973 122.731415,6.64958919 L125.558569,5.65375135 Z'></path>
        </g>
        <g
          id='icon'
          transform='translate(11.839273, 12.162162) scale(-1, 1) translate(-11.839273, -12.162162) translate(0.000000, 0.324324)'
        >
          <path
            d='M9.08218182,4.54054054 C9.08218182,7.04821622 7.04907055,9.08108108 4.54109091,9.08108108 C2.03311452,9.08108108 0,7.04821622 0,4.54054054 C0,2.03286811 2.03311452,0 4.54109091,0 C7.04907055,0 9.08218182,2.03286811 9.08218182,4.54054054 Z'
            fill='#EF7856'
          ></path>
          <path
            d='M9.08218182,19.1351351 C9.08218182,21.6428108 7.04907055,23.6756757 4.54109091,23.6756757 C2.03311452,23.6756757 0,21.6428108 0,19.1351351 C0,16.6274595 2.03311452,14.5945946 4.54109091,14.5945946 C7.04907055,14.5945946 9.08218182,16.6274595 9.08218182,19.1351351 Z'
            fill='#FFFFFF'
          ></path>
          <path
            d='M23.6785455,4.54054054 C23.6785455,7.04821622 21.6454342,9.08108108 19.1374545,9.08108108 C16.6294749,9.08108108 14.5963636,7.04821622 14.5963636,4.54054054 C14.5963636,2.03286811 16.6294749,0 19.1374545,0 C21.6454342,0 23.6785455,2.03286811 23.6785455,4.54054054 Z'
            fill='#FFFFFF'
          ></path>
          <polygon
            fill='#EF7856'
            points='3.29851869 5.47624865 5.39332393 3.38166486 20.7496391 18.7361189 18.6548015 20.8306703'
          ></polygon>
          <path
            d='M23.6785455,19.1351351 C23.6785455,21.6428108 21.6454342,23.6756757 19.1374545,23.6756757 C16.6294749,23.6756757 14.5963636,21.6428108 14.5963636,19.1351351 C14.5963636,16.6274595 16.6294749,14.5945946 19.1374545,14.5945946 C21.6454342,14.5945946 23.6785455,16.6274595 23.6785455,19.1351351 Z'
            fill='#EF7856'
          ></path>
        </g>
      </svg>
    </Link>
    <NavIcon />
    <Navigation showPositions />
  </header>
);

export default Header;
