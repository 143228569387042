import React from 'react';
import Layout from '../components/layout/layout';
import Wrapper from '../components/layout/wrapper';
import { ButtonGroup, ButtonLink } from '../components/layout/buttons';
import '../style/pages/_error.scss';

const ErrorPage = () => (
  <Layout type='error'>
    <Wrapper center>
      <h1>Ehm... Det har skjedd en feil her</h1>
      <p>
        Dessverre kan det skje fra tid til annen – f.eks kan det være en side
        som er slettet, en URL som er endret, eller noe lignende.
      </p>
      <ButtonGroup>
        <ButtonLink text='Forside' type='filled' to='/' />
        <ButtonLink text='Kontakt oss' type='outline' to='/kontakt' />
      </ButtonGroup>
    </Wrapper>
  </Layout>
);

export default ErrorPage;
